@use 'sass:map';
@use 'sass:string';

$map: (
	'note': (
		// #F1592A
		'value': (241, 89, 42),
	),
	'tag': (
		// #48A1EE
		'value': (72, 161, 238),
	),
	'website': (
		// #57C042
		'value': (87, 192, 66),
	),
	'file': (
		// #818181
		'value': (129, 129, 129),
		'icon': 'icon-document',
	),
	'workspace': (
		// #FAA930
		'value': (250, 169, 48),
	),
	'album': (
		// #FCC600
		'value': (252, 198, 0),
	),
);

:root {
	--__fg-to-bg-opacity: 0.3;

	@each $name, $itemMap in $map {
		$value: map.get($itemMap, 'value');

		--color-node-#{$name}-rgb: #{$value};
		--color-node-#{$name}: rgb(var(--color-node-#{$name}-rgb));
		--color-node-#{$name}-bg: rgba(var(--color-node-#{$name}-rgb), var(--__fg-to-bg-opacity));
	}
}

@each $name, $itemMap in $map {
	$iconName: string.unquote('icon-#{$name}');

	@if map.has-key($itemMap, 'icon') {
		$iconName: map.get($itemMap, 'icon');
	}

	.#{$iconName},
	.#{$name}-color,
	.color-#{$name},
	.node-type-#{$name}-color {
		--color-node-rgb: var(--color-node-#{$name}-rgb);
		--color-node: var(--color-node-#{$name});
		--color-node-bg: var(--color-node-#{$name}-bg);
	}
}

:root {
	.bg-node-type {
		background-color: var(--color-node-bg);

		@for $i from 1 through 10 {
			&-#{10 * $i} {
				background-color: rgba(var(--color-node-rgb), #{0.1 * $i});
			}
		}
	}

	.fc-node-type {
		color: var(--color-node);
	}

	.border-node-type {
		border-color: var(--color-node);
	}
}
