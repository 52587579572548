@use 'sass:map';
@use 'sass:math';
@use 'sass:meta';
@use 'sass:selector';
@use 'sass:string';

// TODO: Check this https://chrome.dev/css-wrapped-2024/#light-dark

$color-white-rgb: (255, 255, 255); // #FFFFFF

/* 
	Generates all the permutations that we have for colors
	that can have different values for light and dark themes.

	They follow this rule: <LIGHT_VALUE><DARK_VALUE>0.
*/
@function getAllLightAndDarkMapPermutations($map) {
	$ret: ();

	@each $lightName, $lightValue in map.get($map, 'light') {
		@each $darkname, $darkValue in map.get($map, 'dark') {
			$permutationName: '#{$lightName}#{$darkname}0';
			$permutationMap: (
				'light': (
					'#{$permutationName}': (
						#{$lightValue},
					),
				),
				'dark': (
					'#{$permutationName}': (
						#{$darkValue},
					),
				),
			);
			$ret: map.deep-merge($ret, $permutationMap);
		}
	}

	@return $ret;
}

/* 
	Generates the hover classes.

	Example:
	```scss
	.bg-primary {
		@includes addHoverClasses(var(--color-primary-500), background-color);
	}
	```

	Generates the following classes:
	
	```css
	.bg-primary-on-hover:hover {
		background-color: var(--color-primary-500);
	}

	.hover-parent:hover .bg-primary-on-hover-parent {
		background-color: var(--color-primary-500);
	}
	```
*/
@mixin addHoverClasses($value, $property, $addRoot: true) {
	@media (hover: hover) {
		&-on-hover:hover {
			@if ($addRoot) {
				@at-root :root & {
					#{$property}: #{$value};
				}
			} @else {
				#{$property}: #{$value};
			}
		}

		&-on-hover-parent {
			@if ($addRoot) {
				@at-root :root .hover-parent:hover & {
					#{$property}: #{$value};
				}
			} @else {
				@at-root #{selector.replace(&, ':root', ':root .hover-parent:hover')} {
					#{$property}: #{$value};
				}
			}
		}
	}
}

/* 
	Generates the alpha classes (from 0 to 95, steps of 5).

	Example:
	```scss
	.bg-primary {
		@includes addAlphaValues('--bg-primary-rgb', background-color);
	}
	```

	Generates the following classes:
	
	```css
	.bg-primary-0 {
		background-color: rgba(var(--bg-primary-rgb), 0);
	}

	.bg-primary-5 {
		background-color: rgba(var(--bg-primary-rgb), 0.05);
	}

	(...)

	.bg-primary-95 {
		background-color: rgba(var(--bg-primary-rgb), 0.95);
	}
	```
*/
@mixin addAlphaValues($cssVarName, $property) {
	@for $i from 0 through 10 {
		&-#{$i * 10} {
			#{$property}: rgba(var(#{$cssVarName}), #{$i * 0.1});
			@include addHoverClasses(rgba(var(#{$cssVarName}), #{$i * 0.1}), $property, false);
		}
	}
}

/* 
	Sets the content when the theme is dark.
*/
@mixin setContentWhenDarkTheme() {
	&[app-theme='auto'][client-theme='dark'],
	&[app-theme='dark'] {
		@content;
	}
}

/* 
	Generates the css variables given a map.

	It needs the function to generate the css variable name.
*/
@mixin setCssVariablesFromValuesMap($map, $cssVarNameFunction) {
	@each $name, $value in $map {
		$cssVarNameRgb: meta.call($cssVarNameFunction, $name);
		$cssVarName: string.slice($cssVarNameRgb, 1, string.length($cssVarNameRgb) - 4);

		#{$cssVarNameRgb}: $value;

		// This is legacy. We should remove this.
		#{$cssVarName}: string.unquote('rgb(var(#{$cssVarNameRgb}))');
	}
}

/*
	This mixin is just a legacy to handle the variables `--<theme>-<var-name>`.

	This is legacy. We should refactor everything to remove this.
*/
@mixin setLegacyCssThemeVariablesFromValuesMap($map, $cssVarNameFunction, $themeName) {
	@each $name, $value in $map {
		$cssVarNameRgbWithDashes: meta.call($cssVarNameFunction, $name);
		$cssVarNameRgbWithoutDashes: string.slice($cssVarNameRgbWithDashes, 3);

		$cssVarNameRgb: '--#{$themeName}-#{$cssVarNameRgbWithoutDashes}';
		$cssVarName: string.slice($cssVarNameRgb, 1, string.length($cssVarNameRgb) - 4);

		#{$cssVarNameRgb}: $value;

		// This is double legacy. We should remove this.
		#{$cssVarName}: string.unquote('rgb(var(#{$cssVarNameRgb}))');
	}
}

/*
	Generates the css variables given a map with 'light' and (optionally) 'dark' keys.

	It needs the function to generate the css variable name.
*/
@mixin setCssVariablesFromLightAndDarkMap($map, $cssVarNameFunction, $omitDarkWarning: false) {
	@if map.has-key($map, 'light') == false {
		@error 'The map printed above must have a "light" key. Use "setCssVariablesFromValuesMap" otherwise.';
	}

	@if map.has-key($map, 'dark') == false {
		@if $omitDarkWarning == false {
			@warn 'The map printed above doesn\'t have a dark key. Set the variable "$omitDarkWarning" to false to omit this warning.';
		}
	}

	@include setCssVariablesFromValuesMap(map.get($map, 'light'), $cssVarNameFunction);

	@include setLegacyCssThemeVariablesFromValuesMap(
		map.get($map, 'light'),
		$cssVarNameFunction,
		'light'
	);

	@if map.has-key($map, 'dark') {
		@include setLegacyCssThemeVariablesFromValuesMap(
			map.get($map, 'dark'),
			$cssVarNameFunction,
			'dark'
		);

		@include setContentWhenDarkTheme {
			@include setCssVariablesFromValuesMap(map.get($map, 'dark'), $cssVarNameFunction);
		}
	}
}
